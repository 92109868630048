/**
 * @typedef {Object} IPCRenderer
 * @property {(channel: string, listener: (event: string, ...args: any[]) => void) => void} on
 * @property {(channel: string, listener: (event: string, ...args: any[]) => void) => void} once
 * @property {(channel: string, listener: (...args: any[]) => void) => void} removeListener
 * @property {(channel: string) => void} removeAllListeners
 * @property {(channel: string, ...args: any[]) => void} send
 * @property {(channel: string, ...args: any[]) => Promise<any>} invoke
 * @property {(channel: string, ...args: any[]) => any} sendSync
 * @property {(channel: string, message: any, transfer: MessagePort[]) => any} postMessage
 * @property {(webContentsId: number, channel: string, ...args: any[]) => any} sendTo
 * @property {(channel: string, ...args: any[]) => any} sendToHost
 */

class IPCStrategy {
  /**
   * @type {IPCRenderer}
   */
  ipcRenderer

  /**
   * @type {string}
   */
  channelName

  /**
   *
   * @param {IPCRenderer} ipcRenderer
   * @param {string} channelName
   */
  constructor(ipcRenderer, channelName = 'sm-web') {
    this.ipcRenderer = ipcRenderer
    this.channelName = channelName
  }

  /**
   * Broadcast a message to the given IPCRenderer
   * @public
   * @param {string} message
   * @param {string|number|boolean|Date|Object|File|Blob|FileList|ArrayBuffer} [data=null]
   * @return {void}
   */
  broadcast(message, data = null) {
    this.ipcRenderer.postMessage(this.channelName, { message, data })
  }
}

export default IPCStrategy
