import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

import env from 'app/config/env'
import filterEmptyObjectValues from 'lib/filter-empty-object-values'

import getSharedTheme from './sharedTheme'

const configDarkTheme = filterEmptyObjectValues(
  env('brand_config.theme.darkTheme', {})
)

const baseDarkTheme = getSharedTheme(configDarkTheme)

baseDarkTheme.palette = {
  ...baseDarkTheme.palette,
  ...{
    type: 'dark',
  },
}

const muiColigoTheme = responsiveFontSizes(createTheme(baseDarkTheme))

export default muiColigoTheme
