import { lazy, Suspense } from 'react'

import Loader from 'ui/loader'

/**
 * Function which retries the given function x amount of times.
 * NOTE: The given function must return a promise.
 * @param {() => Promise<any>} fn
 * @param {number} [retriesLeft=5]
 * @param {number} [interval=1000]
 * @return {Promise<any>}
 */
export function retryPromise(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (!retriesLeft) {
            // When there are no retries left we reject the error
            reject(error)
          } else {
            /**
             * When there are retries left we retry the given function.
             * We subtract the "retriesLeft" to ensure that we don't end up in an infinite loop.
             * NOTE: Passing "reject" is crucial to make sure that the error properly bubbles up
             */
            retryPromise(fn, retriesLeft - 1, interval)
              .then(resolve)
              .catch(reject)
          }
        }, interval)
      })
  })
}

/**
 * @param {() => Promise<any>} component Component which should be loaded async
 * @param {Boolean} loader Show a loader while loading the chunk
 * @param {Boolean} retryable Loading the chunk can be retried upon initial failure
 * @returns {Function}
 */
const Loadable = (component, loader = true, retryable = true) => {
  const Component = lazy(retryable ? () => retryPromise(component) : component)
  return props => (
    <Suspense fallback={loader ? <Loader /> : null}>
      <Component {...props} />
    </Suspense>
  )
}

export default Loadable
