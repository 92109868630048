import * as Sentry from '@sentry/browser'

import env from 'app/config/env'

if (env('sentry_config')) {
  const config = {
    environment: 'development', // default if not passed in the config
    release: `summa-meetings@${env('meetings_version')}`,
    serverName: env('api'),
    ...env('sentry_config'),
  }
  Sentry.init(config)
}
