import _ from 'lodash'
import tinycolor from 'tinycolor2'

interface ColorInstance {
  key: string
  hex: string
  rgb: string
  brightness: number
}

const baseBrightnesses: { [key: string]: number } = {
  '50': 245.842,
  '100': 240.119,
  '200': 217.515,
  '300': 193.487,
  '400': 168.964,
  '500': 148.093,
  '600': 119.323,
  '700': 96.401,
  '800': 84.94,
  '900': 63.534,
}

class ColorPaletteGenerator {
  /**
   * Function that parses and returns an ColorInstance object
   *
   * @param value
   * @param key
   */
  private getColorInstance(
    value: tinycolor.Instance,
    key: string
  ): ColorInstance {
    const color: tinycolor.Instance = tinycolor(value)

    return {
      key,
      hex: color.toHexString(),
      rgb: color.toRgbString(),
      brightness: color.getBrightness(),
    }
  }

  /**
   * This function gets the ColorInstance from the colors array whose brightness is closest to the given brightness
   *
   * @param colors
   * @param brightness
   */
  private getClosestColorByBrightness(
    colors: ColorInstance[],
    brightness: number
  ) {
    return colors.reduce(
      (previousColor: ColorInstance, currentColor: ColorInstance) =>
        Math.abs(currentColor.brightness - brightness) <
        Math.abs(previousColor.brightness - brightness)
          ? currentColor
          : previousColor
    )
  }

  /**
   * This function parses a color palette for the given color, and tries to get each color in the palette as close
   * as possible to the expected brightness
   *
   * @param baseLight
   * @param baseDark
   * @param color
   */
  private parsePaletteColors(
    baseLight: tinycolor.ColorFormats.RGBA,
    baseDark: tinycolor.ColorFormats.RGBA,
    color: tinycolor.Instance
  ): ColorInstance[] {
    const mixRatio: number[] = [7, 11, 28, 46, 64, 79, 100, 80, 71, 53]

    return _.map(
      Object.keys(baseBrightnesses),
      (key: string, index: number) => {
        const { hex, brightness } = this.getColorInstance(
          tinycolor.mix(
            +key < 600 ? baseLight : baseDark,
            color,
            mixRatio[index]
          ),
          key
        )

        const brightnessToMatch: number = baseBrightnesses[key]

        let isNotSame = Math.round(brightnessToMatch) !== Math.round(brightness)
        let newColor: tinycolor.Instance = tinycolor(hex)

        do {
          const newColorBrightness: number = tinycolor(newColor).getBrightness()
          const isLighter =
            Math.round(newColorBrightness) > Math.round(brightnessToMatch)

          isNotSame =
            Math.round(newColorBrightness) !== Math.round(brightnessToMatch)

          if (isNotSame) {
            newColor =
              tinycolor(newColor)[isLighter ? 'darken' : 'brighten'](0.25)
          }
        } while (isNotSame)

        return {
          key,
          hex: tinycolor(newColor).toHexString(),
          rgb: tinycolor(newColor)
            .toRgbString()
            .replace('rgb(', '')
            .replace(')', ''),
          brightness: tinycolor(newColor).getBrightness(),
        }
      }
    )
  }

  /**
   * This function creates a color palette for the given color, and tries to get each color in the palette as close
   * as possible to the expected brightness
   *
   * @param color
   */
  private createPaletteByType(
    color: string | tinycolor.Instance
  ): ColorInstance[] {
    const baseLight: tinycolor.ColorFormats.RGBA = tinycolor('#ffffff').toRgb()
    const baseDark: tinycolor.ColorFormats.RGBA = tinycolor('#000000').toRgb()
    const brightnessToMatch: number = baseBrightnesses['600']

    // Parse an intermediate palette based on the given color
    const intermediatePalette: ColorInstance[] = this.parsePaletteColors(
      baseLight,
      baseDark,
      tinycolor(color)
    )

    // Find the the color in the intermediate palette whose brightness is closest to the brightnessToMatch value.
    // This color will from now on be used to generate the color palette
    const { hex: closestColor, brightness: closestBrightness } =
      this.getClosestColorByBrightness(intermediatePalette, brightnessToMatch)

    // Darken or lighten the hex color so its' brightness matches the brightnessToMatch variable as close as possible
    const parsedColor600: tinycolor.Instance = tinycolor.mix(
      brightnessToMatch > closestBrightness ? baseDark : baseLight,
      closestColor,
      100 + Math.abs(brightnessToMatch - closestBrightness)
    )

    return this.parsePaletteColors(
      baseLight,
      baseDark,
      tinycolor(parsedColor600)
    )
  }

  /**
   * This function returns a boolean based on whether the given color is a valid color or not
   *
   * @param color The string to validate
   */
  private validateColor(color: string): boolean {
    return tinycolor(color).isValid()
  }

  /**
   * This function returns a color-palette object that is parsed with the given brand (and accent) color
   *
   * @param brandColor The brand color to generate a color palette for
   * @param accentColor The accent color to generate a color palette for
   */
  public generate(brandColor: string, accentColor?: string) {
    if (!brandColor?.length) {
      throw new Error('No brand color is given')
    }
    // Check if the brand color is valid
    if (!this.validateColor(brandColor)) {
      throw new Error(`The given brand color ${brandColor} is not valid`)
    }

    // If an accent color is given, check if its' value is valid
    if (accentColor?.length && !this.validateColor(accentColor)) {
      throw new Error(`The given accent color ${accentColor} is not valid`)
    }

    const brandPalette: ColorInstance[] = this.createPaletteByType(brandColor)

    const complementaryColor: tinycolor.Instance = tinycolor(
      brandPalette[6].hex
    ).complement()

    const accentPalette: ColorInstance[] = this.createPaletteByType(
      accentColor || complementaryColor
    )

    const reducedBrandPalette = [
      `--color-brand-main: ${_.find(brandPalette, { key: '600' })?.rgb ?? ''}`,
      ..._.map(brandPalette, ({ key, rgb }) => `--color-brand-${key}: ${rgb}`),
    ]

    const reducedAccentPalette = [
      `--color-accent-main: ${
        _.find(accentPalette, { key: '600' })?.rgb ?? ''
      }`,
      ..._.map(
        accentPalette,
        ({ key, rgb }) => `--color-accent-${key}: ${rgb}`
      ),
    ]

    return { brand: reducedBrandPalette, accent: reducedAccentPalette }
  }
}

export default ColorPaletteGenerator
