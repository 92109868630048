import PropTypes from 'prop-types'
import { CircularProgress, Grid } from '@material-ui/core'

import { withStyles } from 'app/ui'

const styles = theme => ({
  root: {
    backgroundColor: 'rgb(var(--color-neutral-800))',
    height: '100%',
  },
})

const Loader = ({ classes }) => (
  <Grid
    container
    alignContent="center"
    className={classes.root}
    justify="center"
  >
    <CircularProgress size={120} thickness={2} />
  </Grid>
)

Loader.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles, { name: 'Loader' })(Loader)
