import env from 'app/config/env'
import browserUtils from 'lib/browser-utils'

import broadcastContext from './broadcast-context'
import IPCStrategy from './ipc-strategy'
import IFrameStrategy from './iframe-strategy'

export const strategies = {
  IPCStrategy,
  IFrameStrategy,
}

export { default as events } from './events'
export { default as broadcastContext } from './broadcast-context'

export function initialize(iframeUrl = false) {
  const configuration = env('event_broadcaster')
  const allowedStragies = (configuration && configuration.strategies) || []

  if (
    browserUtils.isElectron() &&
    window.ipcRenderer &&
    allowedStragies.includes('ipc')
  ) {
    broadcastContext.setStrategy(new strategies.IPCStrategy(window.ipcRenderer))
  } else if (iframeUrl && allowedStragies.includes('iframe')) {
    const iframe = document.createElement('iframe')
    iframe.setAttribute('src', decodeURIComponent(iframeUrl))
    iframe.setAttribute('height', '0')
    iframe.setAttribute('width', '0')
    iframe.style.width = '0px'
    iframe.style.height = '0px'
    iframe.style.display = 'none'
    document.body.appendChild(iframe)
    broadcastContext.setStrategy(
      new strategies.IFrameStrategy(
        iframe.contentWindow,
        decodeURIComponent(iframeUrl)
      )
    )
  }

  broadcastContext.startHeartbeatInterval()
}
