/* eslint-disable import/first */
import './app/services/sentry'

import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom'

import urlUtils from 'lib/url-utils'
import env from 'app/config/env'
import { initialize as initializeBroadcaster } from './app/services/events'
import analytics from 'app/services/analytics'
import browserUtils from 'lib/browser-utils'
import { bootstrapBrandLibrary } from 'lib/ccl/bootstrap'

import Loadable from './ui/loadable'

bootstrapBrandLibrary()

const LoadableBlockerForm = Loadable(
  () => import('./app/features/app/blocker-form')
)
const LoadableNoConfigForm = Loadable(
  () => import('./app/features/app/no-config-form')
)
const LoadableRoot = Loadable(() => import('app/features/root.component'))

const NoConfigForm = () => <LoadableNoConfigForm />

const reason = !browserUtils.isSupportedBrowser()
  ? 'browser'
  : !browserUtils.isSupportedVersion()
    ? 'version'
    : undefined

function getRootElement() {
  if (!env('api')) {
    return <NoConfigForm />
  }

  if (reason && window.location.pathname !== '/signup') {
    return <LoadableBlockerForm reason={reason} />
  }

  return <LoadableRoot />
}

analytics.page()

initializeBroadcaster(urlUtils.getUrlParams().i)

ReactDOM.render(
  <StrictMode>{getRootElement()}</StrictMode>,
  document.getElementById('root'));

