import { withStyles as withStylesWithoutDefault } from '@material-ui/styles'

import defaultTheme from './defaultTheme'

function withStyles(stylesOrCreator, options) {
  return withStylesWithoutDefault(stylesOrCreator, {
    defaultTheme,
    index: 1,
    ...options,
  })
}

export default withStyles
