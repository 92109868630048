import _ from 'lodash'

import env from 'app/config/env'
import { FONTS } from 'constants/constants'

/**
 * Function that parses and returns a theme object.
 *
 * Almost all of the values have `!important` applied to them, because it should
 * always overwrite any default values.
 * @param {Object} brandConfigTheme
 * @returns {Object}
 */
export default function getSharedTheme() {
  const measurements = {
    logo: {
      height: 38,
      width: 192,
    },
    videoStreamerPlayer: {
      width: 700,
    },
  }

  const palette = {
    primary: {
      light: 'rgb(var(--color-brand-400)) !important',
      main: 'rgb(var(--color-brand-500)) !important',
      dark: 'rgb(var(--color-brand-600)) !important',
      contrastText: 'rgb(var(--color-neutral-50)) !important',
    },
    secondary: {
      light: 'rgb(var(--color-accent-400)) !important',
      main: 'rgb(var(--color-accent-500)) !important',
      dark: 'rgb(var(--color-accent-600)) !important',
    },
    error: {
      light: 'rgb(var(--system-color-error-light)) !important',
      main: 'rgb(var(--system-color-error-main)) !important',
      dark: 'rgb(var(--system-color-error-main)) !important',
    },
    warning: {
      light: 'rgb(var(--system-color-warning-light)) !important',
      main: 'rgb(var(--system-color-warning-main)) !important',
      dark: 'rgb(var(--system-color-warning-main)) !important',
    },
    info: {
      light: 'rgb(var(--system-color-info-blue-light)) !important',
      main: 'rgb(var(--system-color-info-blue-main)) !important',
      dark: 'rgb(var(--system-color-info-blue-main)) !important',
    },
    success: {
      light: 'rgb(var(--system-color-success-light)) !important',
      main: 'rgb(var(--system-color-success-main)) !important',
      dark: 'rgb(var(--system-color-success-main)) !important',
    },
    background: {
      canvas: {
        light: 'rgba(var(--color-neutral-700)) !important',
        main: 'rgba(var(--color-neutral-main)) !important',
        dark: 'rgba(var(--color-neutral-900)) !important',
      },
      avatar: 'rgba(var(--color-neutral-700)) !important',
      toolbar: 'rgba(var(--color-neutral-600)) !important',
    },
    action: {
      disabled: 'rgba(var(--color-neutral-700), var(--opacity-3)) !important',
      disabledContrast:
        'rgba(var(--color-neutral-50), var(--opacity-2)) !important',
      disabledBackground: 'rgba(var(--color-neutral-300)) !important',
    },
    inactive: 'rgba(var(--color-neutral-300)) !important',
  }

  /**
   * overrides provide options to do global overrides of component style values
   * @see https://material-ui.com/customization/globals/#global-css
   */
  const overrides = {
    TabMenuEmptyMessage: {
      root: {
        color:
          'rgba(var(--font-color-ondark-default), var(--opacity-4)) !important',
      },
    },
    MuiAvatar: {
      root: {
        color: 'rgb(var(--font-color-ondark-default)) !important',
        fontSize: '1rem',
      },
      colorDefault: {
        color: 'rgb(var(--font-color-ondark-default)) !important',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '0.8125rem',
        fontWeight: 300,
      },
      gutterBottom: {
        marginBottom: '0.75em',
      },
    },
    MuiFab: {
      secondary: {
        color: 'rgb(var(--system-color-error-light)) !important',
        backgroundColor: 'rgb(var(--system-color-error-main)) !important',
      },
    },
  }

  /**
   * global overrides on component props
   * @see https://material-ui.com/customization/globals/#default-props
   */
  const props = {
    MuiLink: {
      color: 'textPrimary !important',
      underline: 'always',
    },
  }

  const zIndex = {
    mobileStepper: 1000,
    speedDial: 1050,
    privateChat: 1099,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  }

  const typographyConfig = {
    headers: {
      fontFamily: 'var(--font-family-brand) !important',
      fontWeight: 'var(--font-weight-700) !important',
    },
    other: {
      fontFamily: `${FONTS.emojiFont}, var(--font-family-ui) !important`,
      lineHeight: '1.4 !important',
    },
  }

  return _.merge({
    palette,

    measurements,

    fontFamily: `${FONTS.emojiFont}, Roboto, sans-serif`,

    typography: {
      h1: typographyConfig.headers,
      h2: typographyConfig.headers,
      h3: typographyConfig.headers,
      h4: typographyConfig.headers,
      h5: typographyConfig.headers,
      h6: typographyConfig.headers,
      subtitle1: {
        ...typographyConfig.other,
        fontWeight: 'var(--font-weight-500) !important',
      },
      subtitle2: {
        ...typographyConfig.other,
        fontWeight: 'var(--font-weight-700) !important',
      },
      body1: {
        ...typographyConfig.other,
        fontWeight: 'var(--font-weight-400) !important',
      },
      body2: {
        ...typographyConfig.other,
        fontWeight: 'var(--font-weight-400) !important',
      },
      button: {
        ...typographyConfig.other,
        fontWeight: 'var(--font-weight-600) !important',
      },
      caption: {
        ...typographyConfig.other,
        fontWeight: 'var(--font-weight-500) !important',
      },
      overline: {
        ...typographyConfig.other,
        fontWeight: 'var(--font-weight-400) !important',
      },
    },

    shape: {
      borderRadius: 'var(--border-radius-interactive-small) !important',
    },

    drawer: {
      width: 320,
    },

    backgroundImage: {
      backgroundAttachment: 'fixed',
      backgroundImage: `linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,0.5)),url(${env(
        'brand_config.background.url'
      )})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      fontStretch: 'normal',
      fontStyle: 'normal',
      height: '100%',
      width: '100%',
    },

    zIndex,

    overrides,

    props,
  })
}
