import Plausible from 'plausible-tracker'
import _ from 'lodash'

const defaultConfig = {
  enabled: true,
}

function plausiblePlugin(pluginConfig) {
  const context = {}

  return {
    name: 'plausible-analytics',
    config: {
      ...defaultConfig,
      ...pluginConfig,
    },

    initialize: ({ config }) => {
      const { apiHost, domain, hashMode, trackLocalhost } = config

      context.plausible = Plausible(
        _.pickBy(
          {
            apiHost,
            domain,
            hashMode,
            trackLocalhost,
          },
          Boolean
        )
      )
    },

    loaded: () => {
      return !!context.plausible
    },

    page: ({ payload }) => {
      const { properties } = payload
      context.plausible.trackPageview({}, { props: properties })
    },

    // Set parameter scope at event level with 'event' method
    track: ({ payload }) => {
      const { properties, event } = payload
      context.plausible.trackEvent(event, { props: properties })
    },
  }
}

export default plausiblePlugin
