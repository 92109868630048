import Analytics from 'analytics'
import eventValidation from 'analytics-plugin-event-validation'

import env from 'app/config/env'

import plausiblePlugin from './plausible-plugin'

const analytics = Analytics({
  app: 'sm-web',
  version: env('meetings_version'),
  plugins: [
    eventValidation({
      // Namespace of current application
      context: 'web',
      // Allowed objects
      objects: [
        'backgroundEffect', // example sm-web:backgroundEffect_blurStarted
      ],
    }),
    plausiblePlugin({
      enabled: env('analytics.enabled', false),
      domain: env('analytics.domainName', window.location.hostname),
    }),
  ],
})

export default analytics
