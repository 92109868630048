import _ from 'lodash'

/**
 *
 * @param {string} objValue
 * @param {string} srcValue
 * @returns {string|undefined}
 */
function mergeClassesCustomizer(objValue, srcValue) {
  if (objValue) return objValue.concat(' ', srcValue).trim()
  return undefined
}

/**
 *
 * @param  {Object<string, string>[]} args
 * @return {Object<string, string>}
 */
function mergeClasses(...args) {
  return _.mergeWith(...args, mergeClassesCustomizer)
}

export default mergeClasses
