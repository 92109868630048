import _ from 'lodash'

const KEY_PREFIX = 'REACT_APP_'

function keyToPath(key, keyPrefix = KEY_PREFIX) {
  const parts = key.split('.')
  parts[0] = parts[0].toUpperCase()
  return `${keyPrefix}${parts.join('.')}`
}

/**
 * sets a config variable
 * @param {string} key
 * @param {*} value
 * @param {string} keyPrefix
 */
export function setEnv(key, value, keyPrefix = KEY_PREFIX) {
  const path = keyToPath(key, keyPrefix)
  _.set(window, path, value)
}

/**
 * Get an environment parameter based on the key
 * @param {string} key
 * @param {*} fallback value returned as fallback
 * @param {string} keyPrefix string prepended to key, defaults to 'REACT_APP'
 * @param {boolean} uppercase uppercase key before getting its value from context defaults to true
 * @param {*} context context to return the key from
 * @returns value from context, can be any type
 */
export function env(key, fallback = undefined, keyPrefix = KEY_PREFIX) {
  if (key === 'meetings_version') {
    return VERSION
  }
  const path = keyToPath(key, keyPrefix)
  return _.get(window, path, fallback)
}

export default env
