class IFrameStrategy {
  /**
   * @type {Window}
   */
  target

  /**
   * @type {string}
   */
  origin

  /**
   * @param {Window} target
   * @param {string} origin
   */
  constructor(target, origin) {
    this.target = target
    this.origin = origin
  }

  /**
   *
   * @param {string} event
   * @param {string|number|boolean|Date|Object|File|Blob|FileList|ArrayBuffer} [payload=null]
   */
  broadcast(event, payload = null) {
    this.target.postMessage({ event, payload }, this.origin)
  }
}

export default IFrameStrategy
