import '@enreach/brand-library'

import env from 'app/config/env'

import { getCustomTokens, setCustomTokens } from './bootstrap-utils'

/**
 * Imports the core component library web components
 *
 * @return {Promise<void>}
 */
async function bootstrapCCL() {
  return (
    await import('@enreach/core-component-library/dist/loader')
  ).defineCustomElements(window)
}

/**
 * Bootraps the brand library based on the environemnt
 */
export async function bootstrapBrandLibrary() {
  const customTokens = getCustomTokens()

  return new globalThis.BrandLibrary()
    .setBrand(env('ccl_config.brand', 'neutral'))
    .then(() => setCustomTokens(customTokens))
}

/**
 * Bootstraps the core component library and brand library
 */
// eslint-disable-next-line consistent-return
export default async function bootstrap() {
  try {
    await bootstrapCCL()
    return await bootstrapBrandLibrary()
  } catch (e) {
    console.error('Unable to load core component library')
    console.error(e)
  }
}
