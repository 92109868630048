import _ from 'lodash'

export function shouldFilterValue(value) {
  if (typeof value === 'undefined') return true
  if (_.isArrayLike(value) || typeof value === 'object') return _.isEmpty(value)
  return false
}

export default function filterEmptyObjectValues(object) {
  return _.keys(object).reduce((acc, key) => {
    let value = object[key]

    if (Array.isArray(value)) value = _.reject(value, shouldFilterValue)
    else if (typeof value === 'object') value = filterEmptyObjectValues(value)

    if (shouldFilterValue(value)) return acc

    return { ...acc, [key]: value }
  }, {})
}
