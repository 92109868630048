import tinycolor from 'tinycolor2'
import _ from 'lodash'

import filterEmptyObjectValues from 'lib/filter-empty-object-values'
import env from 'app/config/env'

import ColorPaletteGenerator from './color-palette-generator'

const tokenTypes = {
  BRAND: 'brand',
  ACCENT: 'accent',
  NEUTRAL: 'neutral',
} as const

const tokenKeys = {
  50: 5,
  100: 10,
  200: 20,
  300: 30,
  400: 40,
  500: 50,
  600: 60,
  700: 70,
  800: 80,
  900: 90,
} as const

type TokenType = typeof tokenTypes[keyof typeof tokenTypes]
type TokenKeyType = typeof tokenKeys[keyof typeof tokenKeys]

type ConfigPaletteType = {
  primary?: { main?: string; dark?: string; light?: string }
  secondary?: { main?: string; dark?: string; light?: string }
}

/**
 * Function that handles darkening/lightening a given color by a given amount
 *
 * @param color Color to change the brightness of
 * @param amount Amount to change the brightness by
 * @param type Determines whether the color should be darkened or lightened
 */
function changeBrightness(
  color: string,
  amount: TokenKeyType,
  type: 'darken' | 'lighten'
): string {
  return tinycolor
    .mix(
      tinycolor(color).toHexString(),
      type === 'darken' ? '#000000' : '#FFFFFF',
      amount
    )
    .toRgbString()
    .replace('rgb(', '')
    .replace(')', '')
}

/**
 * Function that creates and returns a string of lightened and darkened
 * CSS variables for the given color of the given type
 *
 * @param color Color to change the brightness of
 * @param tokenType Type of the token to change the brightness of
 */
export function getLightDarkTokens(
  color: string,
  tokenType: TokenType
): string {
  return _.map(tokenKeys, (amount, tokenKey) => {
    return `
    --color-${tokenType}-darkened-${tokenKey}: ${changeBrightness(
      color,
      amount,
      'darken'
    )};
    --color-${tokenType}-lightened-${tokenKey}: ${changeBrightness(
      color,
      amount,
      'lighten'
    )};
    `
  }).join('')
}

/**
 * Function that creates and returns a string of brand- and/or accent CSS variables
 * based on whether the primary or secondary color is set in the config
 */
export function getCustomTokens(): string {
  // Get the primary and/or secondary palettes from the config
  const { primary: brandPalette, secondary: accentPalette } =
    filterEmptyObjectValues(
      env('brand_config.theme.darkTheme.palette', {})
    ) as ConfigPaletteType

  const brandColor =
    brandPalette?.main ?? brandPalette?.dark ?? brandPalette?.light ?? ''

  const accentColor =
    accentPalette?.main ?? accentPalette?.dark ?? accentPalette?.light ?? ''

  const colorPaletteGenerator = new ColorPaletteGenerator()

  switch (true) {
    // If the brand color is given, overwrite the brand- and accent tokens
    case !!brandColor: {
      const colorPalette = colorPaletteGenerator.generate(
        brandColor,
        accentColor
      )

      return `${colorPalette.brand.join(';')};${colorPalette.accent.join(';')};`
    }

    // If only the accent color is given, overwrite the accent tokens
    case !!accentColor: {
      const colorPalette = colorPaletteGenerator.generate(accentColor)

      return `${colorPalette.accent.join(';')};`
    }

    default:
      return ''
  }
}

/**
 * Function that handles overriding the default tokens with custom tokens
 *
 * @param customTokens String of custom brand- and/or accent CSS variables
 */
export function setCustomTokens(customTokens: string): void {
  const tokenDictionaryStyleElement =
    document.getElementsByClassName('token-dictionary')?.[0]

  if (tokenDictionaryStyleElement) {
    const documentStyle = getComputedStyle(document.documentElement)

    const colorBrandMain = customTokens
      ? `rgb(${/--color-brand-main:\s([^;]+)/g.exec(customTokens)?.[1]})`
      : `rgb(${documentStyle.getPropertyValue('--color-brand-main')})`

    const colorAccentMain = customTokens
      ? `rgb(${/--color-accent-main:\s([^;]+)/g.exec(customTokens)?.[1]})`
      : `rgb(${documentStyle.getPropertyValue('--color-accent-main')})`

    const colorNeutralMain = `rgb(${documentStyle.getPropertyValue(
      '--color-neutral-main'
    )})`

    tokenDictionaryStyleElement.innerHTML =
      tokenDictionaryStyleElement.innerHTML.replace('}}', '') +
      `${customTokens || ''} ` +
      getLightDarkTokens(colorBrandMain, 'brand') +
      ';' +
      getLightDarkTokens(colorAccentMain, 'accent') +
      ';' +
      getLightDarkTokens(colorNeutralMain, 'neutral') +
      '}}'
  }
}
